html, body {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

@font-face {
  font-family: "roboto-regular";
  src: url(./font/Roboto-Regular.ttf) format("truetype");
}
